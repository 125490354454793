export const PROJECT_ID = 'onlyone-pro-develop';
export const CLIENT_ID = '665614392460-bml8115mo026u3mh9cn6gvffla9m3r2i.apps.googleusercontent.com';

export const BASE_URL = 'https://api-dev.onlyonecard.pro';
//export const BASE_URL = 'http://localhost:8080';
export const REDIRECT_URL = 'https://backend-dev.onlyonecard.pro';
//export const REDIRECT_URL = 'http://localhost:3000';
export const API_KEY = 'AIzaSyCmgHtGBwYZ5CL7eUZNyhuaDg8W9y7JfoE';
export const BACKEND_API_BASE_URL = 'https://jarvis-dev.onlyonecard.pro';
export const FRIDAY_API_BASE_URL = 'https://friday-dev.onlyonecard.pro';
export const CEREBRO_API_BASE_URL = 'https://cerebro-dev.onlyonecard.pro';
export const SMAUG_API_BASE_URL = 'https://smaug-dev.onlyonecard.pro';
export const SIDEKICK_API_BASE_URL = 'https://sidekick-dev.onlyonecard.pro';
export const NOTIF_CENTER_API_BASE_URL = 'https://notif-center-dev.onlyonecard.pro';
export const SAURON_API_BASE_URL = 'https://sauron-dev.onlyonecard.pro';
export const SCROOGE_API_BASE_URL = 'https://scrooge-dev.onlyonecard.pro';

//export const BACKEND_API_BASE_URL = 'http://localhost:8080';
export const ONLYONE_WEB_APP_BASE_URL = 'https://app-dev.onlyonecard.pro';
